html {
  scroll-behavior: smooth;
}

hr {
  position: relative;
  border: none;
  height: 2px !important;
  background: white;
}

/* All the CSS for the home page */

.menu {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15vh;
  z-index:5;
}

.menu-link {
  /*background-color: black;*/
  font-size: 1.3rem;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  padding-right: 6px;
  padding-top: 2px;
  margin-bottom: 12px;
  text-align: right;
  text-decoration: none;
  transition: 0.5s;
}

.menu-link:hover {
  background-color: white;
  color: black;
  padding-bottom: 30px;
}

.title {
  border-bottom: 2px solid white;
  padding-bottom: 10px;
}
.title img {
  filter: invert(1);
}

.footer {
  margin: 0 auto;
}
.footer .social {
  color: white;
  text-align: center;

  background-color: transparent;
  display: inline-block;
}

.mobile-content{
  display:none; 
}

@media only screen and (max-width: 768px) {
  .mobile-content {
    display: inline; 
  }
  .desktop-content{
    display:none; 
  }
}

/* All the CSS for the pricing/getting started page */

.getting-started {
  margin: 0 2vw;
}

.prices-title {
  font-size: 2.2rem;
  margin: 0px 0vw;
  margin-bottom: 2vh;
  text-align: center;
  font-family: 'Oswald', sans-serif;
  text-transform: capitalize;
}

.prices-title-caption {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  margin-bottom: 7vh;
}

.price-block-title-box {
  border-radius: 10px 10px 0px 0px;
}

.price-block-title {
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Oswald', sans-serif;
}

.price-block-text {
  font-family: 'Roboto', sans-serif;
}

.price-block-point {
  padding: 12px 16px;
}

.price-block-point:hover {
  background-color: #1a202c;
  color: white;
}

.price-block-price {
  font-size: 1.5rem;
  text-align: center;
  font-family: 'Oswald', sans-serif;
}

@media only screen and (max-width: 768px) {
  .price-block-mobile {
    margin: 10vh 15vw;
  }
}



/*CSS for the slider */

.slider-content {
  font-family: 'Roboto', sans-serif;
  color: red;
}

.slider-title {
  font-family: 'Oswald', sans-serif;
}

/* All the CSS for the portfolio */

.portfolio-box {
  transition-duration: 0.2s;
}
.portfolio-box:hover {
  transform: scale(1.05);
}
.portfolio h2 {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2.5vh;
}

.portfolio-image-caption h3 {
  font-family: 'Oswald', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 5px;
}
.portfolio-image-caption {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-bottom: 1.9vh;
}

.portfolio-loading {
  top: 50%;
  left: 50%;
}

/* ABOUT US */
.about {
  text-align: center;
  padding: 10px 30px;
}
.about-pic {
  display: inline-block;
  text-align: center;
  padding: 15px 0;
  width: 45%;
  max-width: 400px;
  vertical-align: top;
}
.about-space {
  display: inline-block;
  width: 30px;
}
.about-pic img {
  margin: auto;
}
.about a {
  font-style: italic;
}
.email {
  font-size: 18px;
  margin-bottom: 6px;
  color: white;
  background-color: #fb7185;
  margin-left: auto;
  margin-right: auto;
  border-radius: 90px;
  width:fit-content;
  padding: 2px 12px;
}
.about h2 {
  font-size: 32pt;
  opacity: 1;
  font-family: 'Oswald', sans-serif;
  text-align: center;
}
.about h3 {
  font-size: 18pt;
}
.about h4 {
  font-size: 16pt;
  font-style: bold;
}
.about-text {
  text-align: left;
}
.about-text p {
  margin-left: 25px;
}
@media only screen and (max-width: 500px) {
  .about h3 {
    font-size: 16pt;
  }
}


/* Mobile Navigation Bar */
.app-bar{
  height: 59px; 
  padding: 5px; 
  position: fixed; 
  bottom:0%;
  width: 100%; 
  background-color: black; 
  z-index: 999;
}

.app-bar-icon{
  color:white;
  font-size:25px; 
  padding-top: 2px; 
}

.app-bar-text{
  color: white;
  padding: 5px 5px; 
  font-size: 15px;
  line-height: 90%;
  text-align:center;
}

.app-bar-logo{
  padding-top: 3px; 
  max-width: 35px;
}
